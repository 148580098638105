import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import axios from "axios";

import ProfileTab from "./ProfileTab";
import PurchaseHistoryTab from "./PurchaseHistoryTab";
import UpdatePlan from "./UpdatePlan";

const EditAccount = ({
  modal,
  setModal,
  editUserData,
  userId,
  displayPopup,
}) => {
  const [open] = useState(modal);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [limit] = useState(50);
  const [offset] = useState(0);
  const [purchaseHistoryData, setPurchaseHistoryData] = useState();
  const [updateValue, setUpdateValue] = useState(false);
  let completeButtonRef = useRef(null);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user/payment/${userId}?limit=${limit}&offset=${offset}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPurchaseHistoryData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [userId, updateValue]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        initialFocus={completeButtonRef}
        as="div"
        className="fixed account-modal z-10 inset-0 overflow-y-auto"
        onClose={setModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="overlay-bg fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="account-body  inline-block align-bottom bg-white  overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="close-btn bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setModal(!modal)}
                  ref={completeButtonRef}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="account-detail-cover">
                <div className="title">
                  <h1>Account Details</h1>
                </div>
                <div className="account-tab">
                  <ul className="tab">
                    <li
                      className={currentIndex === 0 ? "active" : ""}
                      onClick={() => setCurrentIndex(0)}
                    >
                      Profile
                    </li>
                    <li
                      className={currentIndex === 1 ? "active" : ""}
                      onClick={() => setCurrentIndex(1)}
                    >
                      Purchase History
                    </li>
                  </ul>
                  <div className="tab-content">
                    {currentIndex === 0 ? (
                      <ProfileTab editUserData={editUserData} />
                    ) : currentIndex === 1 ? (
                      purchaseHistoryData ? (
                        purchaseHistoryData &&
                        purchaseHistoryData.map((data) => (
                          <PurchaseHistoryTab
                            key={data?.id}
                            dataId={data?.id}
                            name={data?.name}
                            orderId={data?.order_id}
                            paymentId={data?.payment_id}
                            purchaseDate={data?.purchase_date}
                            expireDate={data?.expiry_date}
                            paymentMethod={data?.payment_method}
                            amount={data?.order_amount}
                            status={data?.status}
                            updateValue={updateValue}
                            setUpdateValue={setUpdateValue}
                          />
                        ))
                      ) : (
                        <UpdatePlan
                          userId={userId}
                          modal={modal}
                          setModal={setModal}
                          displayPopup={displayPopup}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditAccount;
