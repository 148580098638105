import React from "react";
import Header from "../component/Header";
import UsermangementLayout from "../component/Usermanagement/UsermangementLayout";

const UserManagement = () => {
  return (
    <>
      <Header />
      <UsermangementLayout />
    </>
  );
};

export default UserManagement;
