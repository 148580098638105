import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { BounceLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import UsermangementTable from "./UsermangementTable";
import search from "../../image/search.svg";
import plus from "../../image/plush.svg";
import download from "../../image/download.svg";
import CreateAccount from "../Modal/CreateAccount";
import close from "../../image/close-popup.svg";

const UsermangementLayout = () => {
  const { userContextState } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);
  const [userData, setUserData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(1);
  const [offset1, setOffset1] = useState(1);
  const [items, setItems] = useState(0);
  const [items1, setItems1] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [newsletterData, setNewsletterData] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [downloadMessage, setDownloadMesssage] = useState(false);
  const [showMessage, setShowMessage] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userContextState.isLoggedIn === true) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);

  const filter = () => {
    const bodyElement = document.getElementsByClassName("filter")[0];
    if (bodyElement.classList.contains("open")) {
      bodyElement.classList.remove("open");
      setToggleValue(false);
    } else {
      bodyElement.classList.add("open");
      setToggleValue(true);
    }
  };

  useEffect(async () => {
    setLoading(false);
    if (!isNew) {
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user?limit=${limit}&offset=${offset}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      setLoader(true);
      await axios(config)
        .then(function (response) {
          setLoader(false);
          setLoading(false);
          let oldArr = userData;
          let newArr = response.data.data.items;
          let arr = [...oldArr, ...newArr];
          setUserData(arr);
          setItems(response.data.data.meta.totalItems);
        })
        .catch(function (error) {
          setLoader(false);
          setLoading(false);
        });
    }
  }, [offset, isNew, isDataUpdated]);

  useEffect(async () => {
    setLoading(false);
    if (!isNew) {
      var data = "";
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/newsletter?limit=${limit}&offset=${offset1}`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
      };
      setLoader(true);
      axios(config)
        .then(function (response) {
          setLoading(false);
          setLoader(false);
          let oldArr = newsletterData;
          let newArr = response.data.data.items;
          let arr = [...oldArr, ...newArr];
          setNewsletterData(arr);
          setItems1(response.data.data.meta.totalItems);
        })
        .catch(function (error) {
          setLoading(false);
          setLoader(false);
        });
    }
  }, [offset1, isNew]);

  useEffect(async () => {
    setIsNew(true);
    setOffset(1);
    setOffset1(1);
    if (!toggleValue) {
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user?limit=${limit}&offset=${offset}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      setLoading(true);
      await axios(config)
        .then(function (response) {
          setLoading(false);
          setUserData(response.data.data.items);
          setItems(response.data.data.meta.totalItems);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    } else {
      var data = "";
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/newsletter?limit=${limit}&offset=${offset1}`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
      };
      setLoading(true);
      await axios(config)
        .then(function (response) {
          setLoading(false);
          setNewsletterData(response.data.data.items);
          setItems1(response.data.data.meta.totalItems);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }
  }, [toggleValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("data", limit, offset);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user?limit=50&offset=1&search=${searchValue}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setUserData(response.data.data.items);
        setItems(response.data.data.meta.totalItems);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (isDataUpdated) {
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user?limit=50&offset=1&search=${searchValue}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      setLoading(true);
      axios(config)
        .then(function (response) {
          setLoading(false);
          setUserData(response.data.data.items);
          setItems(response.data.data.meta.totalItems);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    }
  }, [isDataUpdated]);

  const handleDownload = () => {
    var data = JSON.stringify({
      user_type: "user",
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user/export`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 201) {
          displayMessage();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const displayMessage = (e) => {
    setDownloadMesssage(true);
    if (!showMessage.show) {
      setShowMessage((prev) => ({ ...prev, show: true }));
      setTimeout(() => {
        setShowMessage((prev) => ({ ...prev, show: false }));
      }, 5000);
    }
  };

  const displayPopup = (e) => {
    if (!showPopup.show) {
      setShowPopup((prev) => ({ ...prev, show: true }));
      setTimeout(() => {
        setShowPopup((prev) => ({ ...prev, show: false }));
      }, 3000);
    }
    setIsDataUpdated(!isDataUpdated);
  };

  return (
    <section className="user-management-block">
      {showMessage.show &&
        (downloadMessage ? (
          <div className="download-csv-message">
            <p>
              We'll send the file to {userContextState.data.profile.email} as
              soon as it's ready
            </p>

            <button
              className="button"
              onClick={() => setDownloadMesssage(false)}
            >
              <img src={close} alt="close icon" />
            </button>
          </div>
        ) : (
          ""
        ))}
      {showPopup.show && (
        <div className="download-csv-message">
          <p>Plan Updated Successfully</p>
        </div>
      )}
      <div className="container">
        <div className="main-title">
          <div className="title">
            <h1>User Management</h1>
            <div className="download-icon" onClick={handleDownload}>
              <img src={download} alt="download" />
              <p className="download-tooltip">Download CSV</p>
            </div>
            {/* {showMessage.show && <p className="download-csv">{downloadMessage}</p>} */}
          </div>
          <div className="serch-filter">
            <form
              className="form-group"
              // onKeyDown={onKeyDownHandler}
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Search users"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  // handleSubmit(e);
                }}
                value={searchValue}
              />
              <div className="search" onClick={handleSubmit}>
                <img src={search} alt="search icon" />
              </div>
            </form>
            <div className="add-user-box filter">
              <label className="switch">
                <input type="checkbox" />
                <span
                  className="slider-box"
                  onClick={() => {
                    filter();
                  }}
                ></span>
              </label>
              <p>Newsletter</p>
            </div>
            <div className="add-user-box" onClick={() => setModal(!modal)}>
              <p>add user</p>
              <img src={plus} alt="plus icon" />
            </div>
          </div>
        </div>
        {!loading ? (
          <UsermangementTable
            userData={!toggleValue ? userData : newsletterData}
            offset={!toggleValue ? offset : offset1}
            setOffset={!toggleValue ? setOffset : setOffset1}
            items={!toggleValue ? items : items1}
            toggleValue={toggleValue}
            setIsNew={setIsNew}
            loader={loader}
            displayPopup={displayPopup}
          />
        ) : (
          <div className="fixed top-0 left-0 right-0 flex items-center justify-center mx-auto h-full before:content-none before:absolute before:left-0 before:right-0 before:w-full before:h-full bg-stone-600 bg-opacity-60">
            <BounceLoader size={150} color={"#BFA682"} loading={true} />
          </div>
        )}
        {modal ? <CreateAccount modal={modal} setModal={setModal} /> : ""}
      </div>
    </section>
  );
};
export default UsermangementLayout;
