import React, { useState } from "react";
import moment from "moment";
import axios from "axios";

import close from "../../image/close-icon.svg";
import rightarrow from "../../image/right-icon.svg";

const ProfileTab = ({ editUserData }) => {
  const [name, setName] = useState(
    editUserData.firstname ? editUserData.firstname : ""
  );
  const [lastName, setLastName] = useState(
    editUserData.lastname ? editUserData.lastname : ""
  );
  const [email] = useState(editUserData.email ? editUserData.email : "");
  const [phone, setPhone] = useState(
    editUserData.contact ? editUserData.contact : ""
  );
  const [regd] = useState(editUserData.source ? editUserData.source : "");
  const [account] = useState(
    editUserData.created_at
      ? moment(editUserData.created_at).format("DD MMM YYYY")
      : ""
  );
  const [plan] = useState(
    editUserData.subscription_type ? editUserData.subscription_type : ""
  );
  const [address, setAddress] = useState(
    editUserData.address_line_1 ? editUserData.address_line_1 : ""
  );
  const [address2] = useState(
    editUserData.address_line_2 ? editUserData.address_line_2 : ""
  );
  const [editName, setEditName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = () => {
    let phoneNo = phone.toString();
    var data = JSON.stringify({
      id: editUserData.id,
      firstname: name,
      lastname: lastName,
      address_line_1: address,
      address_line_2: address2,
      contact: phoneNo,
    });

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function () {
        //response body
      })
      .catch(function (err) {
        if (err) {
          setError(true);
        }
      });
  };

  const handleClickName = () => {
    setName(name);
    setEditName(!editName);
    fetchData();
  };

  const handleClickLastName = () => {
    setLastName(lastName);
    setEditLastName(!editLastName);
    fetchData();
  };

  const handleClickPhone = () => {
    setPhone(phone);
    setEditPhone(!editPhone);
    fetchData();
  };

  const handleClickAddress = () => {
    setAddress(address);
    setEditAddress(!editAddress);
    fetchData();
  };

  return (
    <div className="profile-tab">
      <ul>
        <li>
          <div className={editName ? "profile-row edit-row" : "profile-row"}>
            <div className="profile-col">
              <label>First Name</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                readOnly={!editName ? true : false}
              />
            </div>
            <div className="profile-col">
              {editName ? (
                <div className="update-row">
                  <button onClick={() => setEditName(!editName)}>
                    <img src={close} alt="close icon" />
                  </button>
                  <button onClick={handleClickName}>
                    <img src={rightarrow} alt="right icon" />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="edit">
                {!editName ? (
                  <button onClick={() => setEditName(!editName)}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                        fill="#707070"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div
            className={editLastName ? "profile-row edit-row" : "profile-row"}
          >
            <div className="profile-col">
              <label>Last Name</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                readOnly={!editLastName ? true : false}
              />
            </div>
            <div className="profile-col">
              {editLastName ? (
                <div className="update-row">
                  <button onClick={() => setEditLastName(!editLastName)}>
                    <img src={close} alt="close icon" />
                  </button>
                  <button onClick={handleClickLastName}>
                    <img src={rightarrow} alt="right icon" />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="edit">
                {!editLastName ? (
                  <button onClick={() => setEditLastName(!editLastName)}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                        fill="#707070"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="profile-row">
            <div className="profile-col">
              <label>Email ID</label>
            </div>
            <div className="profile-col ">
              <input
                type="text"
                className="form-control"
                value={email}
                readOnly={true}
              />
            </div>
            <div className="profile-col"></div>
          </div>
        </li>
        <li>
          <div className={editPhone ? "profile-row edit-row" : "profile-row"}>
            <div className="profile-col">
              <label>Mobile Number</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                readOnly={!editPhone ? true : false}
              />
              {error ? (
                <span className="text-red-500 text-sm">
                  Phone no. is already exist
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="profile-col">
              {editPhone ? (
                <div className="update-row">
                  <button onClick={() => setEditPhone(!editPhone)}>
                    <img src={close} alt="close icon" />
                  </button>
                  <button onClick={handleClickPhone}>
                    <img src={rightarrow} alt="close icon" />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="edit">
                {!editPhone ? (
                  <button onClick={() => setEditPhone(!editPhone)}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                        fill="#707070"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="profile-row">
            <div className="profile-col">
              <label>Mode of registration</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={regd}
              />
            </div>
            <div className="profile-col"></div>
          </div>
        </li>
        <li>
          <div className="profile-row">
            <div className="profile-col">
              <label>Account Created On</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                value={account}
                readOnly={true}
              />
            </div>
            <div className="profile-col"></div>
          </div>
        </li>
        <li>
          <div className="profile-row">
            <div className="profile-col">
              <label>Current Plan</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                value={plan}
                readOnly={true}
              />
            </div>
            <div className="profile-col"></div>
          </div>
        </li>
        <li>
          <div className={editAddress ? "profile-row edit-row" : "profile-row"}>
            <div className="profile-col">
              <label>Address</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                readOnly={!editAddress ? true : false}
              />
            </div>
            <div className="profile-col">
              {editAddress ? (
                <div className="update-row">
                  <button onClick={() => setEditAddress(!editAddress)}>
                    <img src={close} alt="close icon" />
                  </button>
                  <button onClick={handleClickAddress}>
                    <img src={rightarrow} alt="close icon" />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="edit">
                {!editAddress ? (
                  <button onClick={() => setEditAddress(!editAddress)}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                        fill="#707070"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </li>
        {/* <li>
          <div className="profile-row">
            <div className="profile-col">
              <label>Address-2</label>
            </div>
            <div className="profile-col">
              <input
                type="text"
                className="form-control"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                readOnly={!editAddress2 ? true : false}
              />
            </div>
            <div className="profile-col">
              {editAddress2 ? (
                <div className="update-row">
                  <button onClick={() => setEditAddress2(!editAddress2)}>
                    <img src={close} alt="close icon" />
                  </button>
                  <button onClick={handleClickAddress2}>
                    <img src={rightarrow} alt="close icon" />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="edit">
                {!editAddress2 ? (
                  <button onClick={() => setEditAddress2(!editAddress2)}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                        fill="#707070"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </li> */}
      </ul>
    </div>
  );
};

export default ProfileTab;
