import React, { useState } from "react";
import moment from "moment";
import axios from "axios";

import close from "../../image/close-icon.svg";
import rightarrow from "../../image/right-icon.svg";

const PurchaseHistoryTab = (props) => {
  const {
    dataId,
    name,
    orderId,
    paymentId,
    purchaseDate,
    expireDate,
    paymentMethod,
    amount,
    status,
    updateValue,
    setUpdateValue,
  } = props;

  const [id] = useState(dataId);
  const [editOrderId] = useState(false);
  const [editPaymentId, setEditPaymentId] = useState(false);
  const [editPurchaseDate] = useState(false);
  const [editExpireDate, setEditExpireDate] = useState(false);

  const [historyOrderId, setHistoryOrderId] = useState(orderId);
  const [historyPaymentId, setHistoryPaymentId] = useState(paymentId);
  const [historyPurchaseDate, setHistoryPurchaseDate] = useState(
    purchaseDate ? moment(purchaseDate).format("DD MMM YYYY") : ""
  );
  const [historyExpireDate, setHistoryExpireDate] = useState(
    expireDate !== null ? moment(expireDate).format("DD MMM YYYY") : ""
  );

  const updateData = () => {
    let expiryDate = moment(historyExpireDate).format("YYYY-MM-DD");
    var data = JSON.stringify({
      id: id,
      payment_id: historyPaymentId,
      expiry_date: expiryDate,
    });
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/order`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setUpdateValue(!updateValue);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClickPaymentId = () => {
    setEditPaymentId(!editPaymentId);
    updateData();
  };

  const handleClickExpireDate = () => {
    setEditExpireDate(!editExpireDate);
    setHistoryExpireDate(moment(historyExpireDate).format("DD MMM YYYY"));
    updateData();
  };

  return (
    <div className="purchase-history-tab">
      <div className="purchase-plan">
        <div className="purchase-plan-title">
          <h2>{name}</h2>
          <ul>
            <li>
              <div
                className={editOrderId ? "profile-row edit-row" : "profile-row"}
              >
                <div className="profile-col">
                  <label>Order ID</label>
                </div>
                <div className="profile-col">
                  <input
                    type="text"
                    id={id}
                    className="form-control"
                    value={historyOrderId}
                    onChange={(e) => setHistoryOrderId(e.target.value)}
                    readOnly={!editOrderId ? true : false}
                  />
                </div>
                <div className="profile-col"></div>
              </div>
            </li>
            <li>
              <div
                className={
                  editPaymentId ? "profile-row edit-row" : "profile-row"
                }
              >
                <div className="profile-col">
                  <label>Payment ID</label>
                </div>
                <div className="profile-col">
                  <input
                    type="text"
                    id={id}
                    className="form-control"
                    value={historyPaymentId}
                    onChange={(e) => setHistoryPaymentId(e.target.value)}
                    readOnly={!editPaymentId ? true : false}
                  />
                </div>
                <div className="profile-col">
                  {editPaymentId ? (
                    <div className="update-row">
                      <button onClick={() => setEditPaymentId(!editPaymentId)}>
                        <img src={close} alt="close icon" />
                      </button>
                      <button onClick={handleClickPaymentId}>
                        <img src={rightarrow} alt="close icon" />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="edit">
                    {!editPaymentId ? (
                      <button
                        onClick={() => {
                          setEditPaymentId(!editPaymentId);
                        }}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                            fill="#707070"
                          />
                        </svg>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className={
                  editPurchaseDate ? "profile-row edit-row" : "profile-row"
                }
              >
                <div className="profile-col">
                  <label>Purchase Date</label>
                </div>
                <div className="profile-col">
                  {editPurchaseDate ? (
                    <input
                      type="date"
                      id={id}
                      className="form-control"
                      value={historyPurchaseDate}
                      onChange={(e) => setHistoryPurchaseDate(e.target.value)}
                    />
                  ) : (
                    <input
                      type="text"
                      id={id}
                      className="form-control"
                      value={
                        historyPurchaseDate !== null ? historyPurchaseDate : ""
                      }
                      readOnly={true}
                    />
                  )}
                </div>
                <div className="profile-col"></div>
              </div>
            </li>
            <li>
              <div
                className={
                  editExpireDate ? "profile-row edit-row" : "profile-row"
                }
              >
                <div className="profile-col">
                  <label>Expire Date</label>
                </div>
                <div className="profile-col">
                  {editExpireDate ? (
                    <input
                      type="date"
                      id={id}
                      className="form-control"
                      value={historyExpireDate}
                      onChange={(e) => setHistoryExpireDate(e.target.value)}
                    />
                  ) : (
                    <input
                      type="text"
                      id={id}
                      className="form-control"
                      value={
                        historyExpireDate !== null ? historyExpireDate : ""
                      }
                      readOnly={true}
                    />
                  )}
                </div>
                <div className="profile-col">
                  {editExpireDate ? (
                    <div className="update-row">
                      <button
                        onClick={() => setEditExpireDate(!editExpireDate)}
                      >
                        <img src={close} alt="close icon" />
                      </button>
                      <button onClick={handleClickExpireDate}>
                        <img src={rightarrow} alt="close icon" />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="edit">
                    {!editExpireDate ? (
                      <button
                        onClick={() => {
                          setEditExpireDate(!editExpireDate);
                        }}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                            fill="#707070"
                          />
                        </svg>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="profile-row">
                <div className="profile-col">
                  <label>Mode of Payment</label>
                </div>
                <div className="profile-col">
                  <input
                    type="text"
                    className="form-control"
                    value={paymentMethod}
                    readOnly={true}
                  />
                </div>
                <div className="profile-col"></div>
              </div>
            </li>
            <li>
              <div className="profile-row">
                <div className="profile-col">
                  <label>Amount</label>
                </div>
                <div className="profile-col">
                  <input
                    type="text"
                    className="form-control"
                    value={amount}
                    readOnly={true}
                  />
                </div>
                <div className="profile-col"></div>
              </div>
            </li>
            <li>
              <div className="profile-row">
                <div className="profile-col">
                  <label>Status</label>
                </div>
                <div className="profile-col">
                  {status === "success" ? (
                    <p className="green">{status}</p>
                  ) : status === "Captured" || status === "captured" ? (
                    <p className="green">{`Success`}</p>
                  ) : (
                    <p className="red">{status}</p>
                  )}
                </div>
                <div className="profile-col"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PurchaseHistoryTab;
