import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import axios from "axios";
import moment from "moment";

const CreateAccount = ({ modal, setModal }) => {
  const [open] = useState(modal);
  const [planData, setPlanData] = useState([]);
  const [selectPlan, setSelectPlan] = useState(null);
  const [planExpiryDate, setPlanExpiryDate] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [activationDate, setActivationDate] = useState("");

  let currentDate = moment().toDate();

  const handleSelect = (e) => {
    setSelectPlan(e.target.value);
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/plan`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPlanData(response.data.data);
        setSelectPlan(response.data.data[0]?.name);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function addDays(date, days) {
    return moment(date).add(days, "days");
  }

  useEffect(() => {
    let data = planData && planData.filter((val) => val.name === selectPlan);
    let result = addDays(activationDate, data[0]?.duration);
    setSubscriptionId(data[0]?.id);
    setPlanExpiryDate(moment(result).format("DD MMM YYYY"));
  }, [selectPlan, activationDate]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      status: "Subscribed",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      accountCreatedOn: moment(currentDate).format("DD MMM YYYY"),
      planActivateDate: "",
      paymentId: "Manual",
      address: "",
    },
  });

  const onSubmit = (data, e) => {
    var userData = JSON.stringify({
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      contact: data.phone !== "" ? data.phone : null,
      address_line_1: null,
      address_line_2: null,
      subscription_id: subscriptionId,
      subscription_expiry: moment(planExpiryDate).format("YYYY-MM-DD"),
      subscription_start: moment(data.planActivateDate).format("YYYY-MM-DD"),
      payment_id: data.paymentId,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: userData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode === 201) {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        if (error) {
          alert("User already exists");
        }
      });

    e.target.reset();
    setModal(!modal);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed account-modal z-10 inset-0 overflow-y-auto"
        onClose={setModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="overlay-bg fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="account-body create-account-modal  inline-block align-bottom bg-white  overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="close-btn bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setModal(!modal)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="account-detail-cover">
                <div className="title">
                  <h1>Create Account</h1>
                </div>
                <form
                  className="account-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <label>Status</label>
                    <input
                      type="text"
                      className="form-control"
                      name="status"
                      {...register("status")}
                    />
                  </div>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstname"
                      {...register("firstname")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      {...register("lastname")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email ID</label>
                    <div className="form-group-inner">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <span className="error">Email is required</span>
                      )}
                    </div>
                  </div>

                  {errors.email?.type === "pattern" && (
                    <span className="text-red-300 text-right text-sm">
                      Invalid email
                    </span>
                  )}

                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      {...register("phone")}
                    />
                  </div>
                  <div className="form-group disabled-row">
                    <label>Account Created On</label>
                    <input
                      type="text"
                      className="form-control"
                      name="accountCreatedOn"
                      readOnly={true}
                      {...register("accountCreatedOn")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Plan activate date</label>
                    <Controller
                      name="planActivateDate"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          onChange={(e) => {
                            setActivationDate(e);
                            field.onChange(e);
                          }}
                          dateFormat="dd MMM yyyy"
                          selected={field.value}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label>Current Plan</label>
                    {activationDate ? (
                      <select
                        className="form-control"
                        value={selectPlan}
                        onChange={handleSelect}
                      >
                        {planData &&
                          planData.map((plan) => (
                            <option key={plan.id} value={plan.name}>
                              {plan.name}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <select
                        className="form-control"
                        value={selectPlan}
                        onChange={handleSelect}
                      >
                        <option>None</option>
                      </select>
                    )}
                  </div>
                  <div className="form-group disabled-row">
                    <label>Plan Expire Date</label>
                    {activationDate && planExpiryDate ? (
                      <input
                        type="text"
                        className="form-control"
                        name="planExpireDate"
                        value={planExpiryDate}
                        readOnly={true}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        name="planExpireDate"
                        value=""
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Payment ID</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paymentId"
                      {...register("paymentId")}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>Address</label>
                    <textarea
                      className="form-control"
                      name="address"
                      {...register("address")}
                    ></textarea>
                  </div> */}
                  <div className="submit-row">
                    <button
                      className="secondary-button"
                      onClick={() => setModal(!modal)}
                    >
                      cancel
                    </button>
                    <button type="submit" className="primary-button">
                      create account
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateAccount;
