import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { isJwtExpired } from "jwt-check-expiration";

import LoginPage from "./LoginPage";
import UserManagement from "./UserManagement";

const Main = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      !isJwtExpired(localStorage.getItem("token")) &&
      localStorage.getItem("user_profile")
    ) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/" element={<UserManagement />} />
    </Routes>
  );
};

export default Main;
