import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import logo from "../image/ad-login.svg";
import mail from "../image/mail.svg";
import arrow from "../image/right-arrow.svg";
import errorIcon from "../image/error.svg";
import { UserContext } from "../context/UserContext";

const Login = () => {
  const { setuserContextState } = useContext(UserContext);
  const [click, setClick] = useState(false);
  const [mailId, setMailId] = useState("");
  const [otp, setOtp] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [counter, setCounter] = useState(59);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data, e) => {
    let emailId = data.email.toLowerCase();
    setClick(!click);
    setMailId(emailId);
    e.target.reset();
  };

  const handleClick = () => {
    var data = JSON.stringify({
      email: mailId,
    });
    console.log(process.env.REACT_APP_PUBLIC_AUTH_URL);

    var config = {
      method: "post",
      url: `https://prod-api.peepultree.in/console/auth/send-otp`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setSuccess(true);
          setCounter(59);
        } else {
          setSuccess(false);
        }
      })
      .catch(() => {
        //catch body
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (mailId !== "" && otp !== null) {
      var loginData = JSON.stringify({
        email: mailId,
        otp: otp,
      });
      console.log(process.env.REACT_APP_PUBLIC_AUTH_URL);
      var loginConfig = {
        method: "post",
        url: `https://prod-api.peepultree.in/console/auth/login`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
          "Content-Type": "application/json",
        },
        data: loginData,
      };

      axios(loginConfig)
        .then(function (response) {
          if (response.data) {
            setError(false);
            setOtpError(false);
            localStorage.setItem("token", response.data.data.accessToken);
            localStorage.setItem(
              "refreshToken",
              response.data.data.refreshToken
            );
            localStorage.setItem(
              "user_profile",
              JSON.stringify(response.data.data.data)
            );
            setuserContextState((dataVal) => ({
              ...dataVal,
              isLoggedIn: true,
              data: response.data.data.data,
            }));
            navigate("/", { replace: true });
          }
        })
        .catch(function (err) {
          if (err) {
            setError(true);
          }
        });
    } else {
      if (otp === null) {
        setOtpError(true);
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className="login">
      <div className="container">
        <div className="login-cover">
          <div className="lg-left">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="lg-right">
            <div className="login-box">
              <h1>Sign In</h1>
              <div className="login-form">
                {!click ? (
                  !success ? (
                    <form className="step-1" onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your email ID"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                          name="email"
                        />
                        <div className="icon">
                          <img src={mail} alt="mail icon" />
                        </div>
                      </div>
                      {errors.email?.type === "required" && (
                        <span className="text-red">Email is required</span>
                      )}
                      {errors.email?.type === "pattern" && (
                        <span className="text-red">Invalid email</span>
                      )}
                      <div className="submit">
                        <button className="button">
                          Login
                          <img src={arrow} alt="arrow" />
                        </button>
                      </div>
                    </form>
                  ) : (
                    ""
                  )
                ) : !success ? (
                  <div className="step-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        readOnly={true}
                        value={mailId}
                      />
                      <div className="icon">
                        <img src={mail} alt="mail icon" />
                      </div>
                    </div>
                    <div className="submit">
                      <button className="button" onClick={handleClick}>
                        Get OTP
                        <img src={arrow} alt="arrow" />
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {success ? (
                  <form className="step-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        readOnly={true}
                        value={mailId}
                      />
                      <div className="icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
                            fill="#707070"
                          />
                        </svg>
                      </div>
                      <div
                        className="edit-icon"
                        onClick={() => {
                          setSuccess(!success);
                          setClick(!click);
                        }}
                      >
                        <a href="#">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.243 18.0001H4.242Z"
                              fill="#707070"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div
                      className={otpError ? "form-group error" : "form-group"}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        name="otp"
                        value={otp}
                      />
                      <div className="icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.917 13.0001C12.6623 14.4802 11.8619 15.811 10.6739 16.7298C9.48593 17.6486 7.99666 18.0886 6.50009 17.963C5.00352 17.8374 3.60841 17.1554 2.59016 16.0514C1.57191 14.9475 1.00452 13.5019 1 12.0001C0.998629 10.4947 1.56319 9.04382 2.58168 7.93527C3.60017 6.82672 4.99816 6.14153 6.49828 6.01565C7.9984 5.88977 9.49103 6.3324 10.68 7.25571C11.869 8.17902 12.6675 9.51555 12.917 11.0001H23V13.0001H21V17.0001H19V13.0001H17V17.0001H15V13.0001H12.917ZM7 16.0001C8.06087 16.0001 9.07828 15.5787 9.82843 14.8285C10.5786 14.0784 11 13.061 11 12.0001C11 10.9392 10.5786 9.92183 9.82843 9.17169C9.07828 8.42154 8.06087 8.00012 7 8.00012C5.93914 8.00012 4.92172 8.42154 4.17158 9.17169C3.42143 9.92183 3 10.9392 3 12.0001C3 13.061 3.42143 14.0784 4.17158 14.8285C4.92172 15.5787 5.93914 16.0001 7 16.0001Z"
                            fill="#707070"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="resend-otp">
                      {counter !== 0 ? (
                        <label>Resend OTP in {counter} secs...</label>
                      ) : (
                        ""
                      )}
                      {counter === 0 ? (
                        <p onClick={handleClick}>Resend OTP</p>
                      ) : (
                        ""
                      )}
                    </div>
                    {error ? (
                      <div className="error-group">
                        <img src={errorIcon} alt="error" />
                        <p>Fill all required fields</p>
                      </div>
                    ) : otpError ? (
                      <div className="error-group">
                        <img src={errorIcon} alt="error" />
                        <p>Please enter OTP</p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="submit">
                      <button className="button" onClick={handleLogin}>
                        login
                        <img src={arrow} alt="arrow" />
                      </button>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
