import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { isJwtExpired } from "jwt-check-expiration";

import { UserContext } from "./context/UserContext";
import Main from "./pages/Main";

function App() {
  const [userContextState, setuserContextState] = useState({
    isLoggedIn: false,
  });

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      !isJwtExpired(localStorage.getItem("token"))
    ) {
      setuserContextState((dataVal) => ({
        isLoggedIn: true,
        data: JSON.parse(localStorage.getItem("user_profile")),
      }));
    }
  }, []);

  return (
    <>
      <UserContext.Provider value={{ userContextState, setuserContextState }}>
        <Router>
          <Main />
        </Router>
      </UserContext.Provider>
    </>
  );
}

export default App;
