import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

import user from "../../image/user.svg";
import EditAccount from "../Modal/EditAccount";

const UsermangementTable = ({
  userData,
  offset,
  setOffset,
  items,
  toggleValue,
  setIsNew,
  loader,
  displayPopup,
}) => {
  const [modal, setModal] = useState(false);
  const [editUserData, setEditUserData] = useState();
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (id) => {
    setEditUserData();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/user/${id}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setUserId(id);
        setEditUserData(response.data.data);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="usermanagement-table">
      <table>
        <tr>
          <th></th>
          <th>Name</th>
          <th>status</th>
          {!toggleValue ? <th>plan</th> : ""}
          {!toggleValue ? <th></th> : ""}
        </tr>

        {userData &&
          userData.map((data, index) => (
            <tr>
              <td>
                <div className="user-icon">
                  <img src={user} alt="user icon" />
                </div>
              </td>
              <td>
                {!toggleValue ? (
                  <>
                    <label>{data.name ? data.name : ""}</label>
                    <p>{data.email ? data.email : ""}</p>
                  </>
                ) : (
                  ""
                )}
                {toggleValue ? <span>{data.email ? data.email : ""}</span> : ""}
              </td>
              <td>
                <label>
                  {toggleValue
                    ? data.created_at
                      ? moment(data.created_at).format("DD MMM YYYY")
                      : ""
                    : data.type
                    ? data.type
                    : ""}
                </label>
                <p>
                  {!toggleValue
                    ? data.created_at
                      ? moment(data.created_at).format("DD MMM YYYY")
                      : ""
                    : ""}
                </p>
              </td>
              {!toggleValue ? (
                <td>
                  <label>
                    {data.plan ? data.plan : ""}
                    <span>{data.status ? ` (${data.status})` : ""}</span>
                  </label>
                  <p>
                    {data.start_date
                      ? moment(data.start_date).format("DD MMM YYYY")
                      : ""}
                  </p>
                </td>
              ) : (
                ""
              )}
              {!toggleValue ? (
                <td>
                  <div
                    className="edit-icon"
                    onClick={() => {
                      setActiveIndex(index);
                      setModal(!modal);
                      handleClick(data.id);
                    }}
                  >
                    {!loading ? (
                      <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9997 0C16.3917 0 20.8777 3.88 21.8187 9C20.8787 14.12 16.3917 18 10.9997 18C5.60766 18 1.12166 14.12 0.180664 9C1.12066 3.88 5.60766 0 10.9997 0ZM10.9997 16C13.0391 15.9996 15.0181 15.3068 16.6125 14.0352C18.207 12.7635 19.3226 10.9883 19.7767 9C19.3209 7.0133 18.2046 5.24 16.6103 3.97003C15.016 2.70005 13.038 2.00853 10.9997 2.00853C8.96136 2.00853 6.98335 2.70005 5.38904 3.97003C3.79473 5.24 2.67843 7.0133 2.22266 9C2.67676 10.9883 3.79234 12.7635 5.38681 14.0352C6.98128 15.3068 8.9602 15.9996 10.9997 16ZM10.9997 13.5C9.80619 13.5 8.6616 13.0259 7.81768 12.182C6.97377 11.3381 6.49966 10.1935 6.49966 9C6.49966 7.80653 6.97377 6.66193 7.81768 5.81802C8.6616 4.97411 9.80619 4.5 10.9997 4.5C12.1931 4.5 13.3377 4.97411 14.1816 5.81802C15.0256 6.66193 15.4997 7.80653 15.4997 9C15.4997 10.1935 15.0256 11.3381 14.1816 12.182C13.3377 13.0259 12.1931 13.5 10.9997 13.5ZM10.9997 11.5C11.6627 11.5 12.2986 11.2366 12.7674 10.7678C13.2363 10.2989 13.4997 9.66304 13.4997 9C13.4997 8.33696 13.2363 7.70107 12.7674 7.23223C12.2986 6.76339 11.6627 6.5 10.9997 6.5C10.3366 6.5 9.70074 6.76339 9.2319 7.23223C8.76306 7.70107 8.49966 8.33696 8.49966 9C8.49966 9.66304 8.76306 10.2989 9.2319 10.7678C9.70074 11.2366 10.3366 11.5 10.9997 11.5Z"
                          fill="#707070"
                        />
                      </svg>
                    ) : index === activeIndex ? (
                      <ClipLoader color={"#000"} loading={loading} size={15} />
                    ) : (
                      <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9997 0C16.3917 0 20.8777 3.88 21.8187 9C20.8787 14.12 16.3917 18 10.9997 18C5.60766 18 1.12166 14.12 0.180664 9C1.12066 3.88 5.60766 0 10.9997 0ZM10.9997 16C13.0391 15.9996 15.0181 15.3068 16.6125 14.0352C18.207 12.7635 19.3226 10.9883 19.7767 9C19.3209 7.0133 18.2046 5.24 16.6103 3.97003C15.016 2.70005 13.038 2.00853 10.9997 2.00853C8.96136 2.00853 6.98335 2.70005 5.38904 3.97003C3.79473 5.24 2.67843 7.0133 2.22266 9C2.67676 10.9883 3.79234 12.7635 5.38681 14.0352C6.98128 15.3068 8.9602 15.9996 10.9997 16ZM10.9997 13.5C9.80619 13.5 8.6616 13.0259 7.81768 12.182C6.97377 11.3381 6.49966 10.1935 6.49966 9C6.49966 7.80653 6.97377 6.66193 7.81768 5.81802C8.6616 4.97411 9.80619 4.5 10.9997 4.5C12.1931 4.5 13.3377 4.97411 14.1816 5.81802C15.0256 6.66193 15.4997 7.80653 15.4997 9C15.4997 10.1935 15.0256 11.3381 14.1816 12.182C13.3377 13.0259 12.1931 13.5 10.9997 13.5ZM10.9997 11.5C11.6627 11.5 12.2986 11.2366 12.7674 10.7678C13.2363 10.2989 13.4997 9.66304 13.4997 9C13.4997 8.33696 13.2363 7.70107 12.7674 7.23223C12.2986 6.76339 11.6627 6.5 10.9997 6.5C10.3366 6.5 9.70074 6.76339 9.2319 7.23223C8.76306 7.70107 8.49966 8.33696 8.49966 9C8.49966 9.66304 8.76306 10.2989 9.2319 10.7678C9.70074 11.2366 10.3366 11.5 10.9997 11.5Z"
                          fill="#707070"
                        />
                      </svg>
                    )}
                  </div>
                </td>
              ) : (
                ""
              )}
            </tr>
          ))}
      </table>
      {modal ? (
        editUserData && userId ? (
          <EditAccount
            modal={modal}
            setModal={setModal}
            editUserData={editUserData}
            userId={userId}
            displayPopup={displayPopup}
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {userData && userData?.length && items !== userData.length ? (
        <div className="load-more-btn">
          <button
            onClick={() => {
              setOffset(offset + 1);
              setIsNew(false);
            }}
          >
            {loader ? (
              <ClipLoader color={"#000"} loading={loading} size={30} />
            ) : (
              "load more"
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UsermangementTable;
