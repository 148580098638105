import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import logo from "../image/logo.svg";
import user from "../image/user.svg";

const Header = () => {
  const { userContextState } = useContext(UserContext);
  const navigate = useNavigate();
  const toggleClass = () => {
    const bodyElement = document.getElementsByClassName("header-right")[0];
    if (bodyElement.classList.contains("open")) {
      bodyElement.classList.remove("open");
    } else {
      bodyElement.classList.add("open");
    }
  };

  const handleClick = () => {
    window.localStorage.clear();
    navigate("/login");
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <Link to="/" onClick={() => navigate("/")}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div
          className="header-right"
          onClick={() => {
            toggleClass();
          }}
        >
          <div className="icon">
            <img src={user} alt="user" />
          </div>
          <p className="user-name">
            {userContextState.data ? userContextState.data.role : ""}
          </p>
          <div className="dropdown-menu" onClick={handleClick}>
            <a href="/login">Logout</a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
