import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment";

const UpdatePlan = ({ userId, modal, setModal, displayPopup }) => {
  const [activationDate, setActivationDate] = useState("");
  const [planData, setPlanData] = useState([]);
  const [selectPlan, setSelectPlan] = useState(null);
  const [planExpiryDate, setPlanExpiryDate] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState("");

  const handleSelect = (e) => {
    setSelectPlan(e.target.value);
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/plan`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        setPlanData(response.data.data);
        setSelectPlan(response.data.data[0]?.name);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function addDays(date, days) {
    return moment(date).add(days, "days");
  }

  useEffect(() => {
    let data = planData && planData.filter((val) => val.name === selectPlan);
    let result = addDays(activationDate, data[0]?.duration);
    setSubscriptionId(data[0]?.id);
    setSubscriptionType(data[0]?.name);
    setPlanExpiryDate(moment(result).format("DD MMM YYYY"));
  }, [selectPlan, activationDate]);

  const { control, register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      orderId: "Manual",
      orderAmount: "",
      paymentId: "",
      planActivateDate: "",
      paymentStatus: "",
      paymentMethod: "",
    },
  });

  function getAmount(amount) {
    if (amount.toString().length) {
      return amount + "00";
    }
    return amount.toString();
  }

  const onSubmit = (data, e) => {
    let order_ID = data.orderId.toLowerCase();
    let orderID = order_ID.charAt(0).toUpperCase() + order_ID.slice(1);
    let amount = getAmount(data.orderAmount);
    let order_Status = data.paymentStatus.toLowerCase();
    let orderStatus =
      order_Status.charAt(0).toUpperCase() + order_Status.slice(1);
    let paymentMode = data.paymentMethod.toLowerCase();

    if (data) {
      var jsonData = JSON.stringify({
        order_id: orderID,
        order_amount: amount,
        order_status: orderStatus,
        user_id: userId,
        subscription_id: subscriptionId,
        payment_id: data.paymentId,
        subscription_start: moment(data.planActivateDate).format("YYYY-MM-DD"),
        subscription_expiry: moment(planExpiryDate).format("YYYY-MM-DD"),
        payment_method: paymentMode,
        subscription_type: subscriptionType,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_PUBLIC_AUTH_URL}/create/order`,
        headers: {
          "x-api-key": `${process.env.REACT_APP_PUBLIC_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        data: jsonData,
      };

      axios(config)
        .then(function (response) {
          displayPopup();
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    e.target.reset();
    setModal(!modal);
  };

  return (
    <div className="account-detail-cover">
      <div className="title pt-2">
        <h1 className="text-center">Update Plan</h1>
      </div>
      <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Order Id</label>
          <input
            type="text"
            className="form-control"
            name="orderId"
            {...register("orderId")}
          />
        </div>
        <div className="form-group">
          <label>Order Amount</label>
          <input
            type="number"
            className="form-control"
            name="orderAmount"
            {...register("orderAmount")}
          />
        </div>
        <div className="form-group">
          <label>Payment Id</label>
          <input
            type="text"
            className="form-control"
            name="paymentId"
            {...register("paymentId")}
          />
        </div>

        <div className="form-group">
          <label>Plan Activate Date</label>
          <Controller
            name="planActivateDate"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                onChange={(e) => {
                  setActivationDate(e);
                  field.onChange(e);
                }}
                dateFormat="dd MMM yyyy"
                selected={field.value}
              />
            )}
          />
        </div>
        <div className="form-group">
          <label>Current Plan</label>
          {activationDate ? (
            <select
              className="form-control"
              value={selectPlan}
              onChange={handleSelect}
            >
              {planData &&
                planData.map((plan) => (
                  <option key={plan.id} value={plan.name}>
                    {plan.name}
                  </option>
                ))}
            </select>
          ) : (
            <select
              className="form-control"
              value={selectPlan}
              onChange={handleSelect}
            >
              <option>None</option>
            </select>
          )}
        </div>
        <div className="form-group">
          <label>Plan Expire Date</label>
          {activationDate && planExpiryDate ? (
            <input
              type="text"
              className="form-control"
              name="planExpireDate"
              value={planExpiryDate}
              readOnly={true}
            />
          ) : (
            <input
              type="text"
              className="form-control"
              name="planExpireDate"
              value=""
              readOnly={true}
            />
          )}
        </div>
        <div className="form-group">
          <label>Payment Status</label>
          <input
            type="text"
            className="form-control"
            name="paymentStatus"
            {...register("paymentStatus")}
          />
        </div>
        <div className="form-group">
          <label>Payment Method</label>
          <input
            type="text"
            className="form-control"
            name="paymentMethod"
            {...register("paymentMethod")}
          />
        </div>

        <div className="submit-row">
          <button type="submit" className="primary-button">
            Update Plan
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePlan;
